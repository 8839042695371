import { getDateNoTime } from '@assets/js/dateUtils';
import helper from '@assets/js/helper';

export const tableProperties = [
  { prop: 'selection', type: 'selection', labelWidth: '48px' },
  { prop: 'index', type: 'index', labelWidth: '50px', label: '序号' },
  {
    prop: 'imge_url',
    itemType: 'input',
    widthAuto: true,
    label: '图片',
    input: false,
    sortable: false,
    subItem: {
      type: 'image',
      thumbnail: row => helper.picUrl(row.imge_url),
      image: row => helper.picUrl(row.imge_url)
    }
  },
  { prop: 'desi_no', itemType: 'input', widthAuto: true, label: '设计单号', sortable: false },
  { prop: 'dequ_no', itemType: 'input', widthAuto: 100, label: '委托设计单号', sortable: false, input: false },
  { prop: 'prod_no', itemType: 'input', widthAuto: true, label: '我司货号', sortable: false },
  { prop: 'prod_name', itemType: 'input', widthAuto: true, label: '产品名称', input: true, sortable: false },
  { prop: 'prod_type', itemType: 'select', widthAuto: true, label: '产品类别', options: [], sortable: false },
  { prop: 'prod_attr', itemType: 'select', widthAuto: true, label: '产品属性', options: [], sortable: false },
  { prop: 'prod_spec', itemType: 'input', input: false, widthAuto: true, label: '产品规格', sortable: false },
  {
    prop: 'desi_stff_name',
    itemType: 'input',
    widthAuto: true,
    label: '设计人',
    sortable: false
  },
  { prop: 'desi_date', itemType: 'date', widthAuto: true, label: '设计日期', formatter: val => getDateNoTime(val, true) },
  { prop: 'desi_rank', itemType: 'select', widthAuto: true, label: '难易等级', options: [], sortable: false },
  { prop: 'stff_name', label: '录入人', itemType: 'input', labelWidth: 120, sortable: false },
  { prop: 'create_time', label: '录入时间', itemType: 'date', labelWidth: '120px', formatter: val => getDateNoTime(val, true) },
  {
    prop: 'status',
    label: '单据状态',
    itemType: 'select',
    fixed: 'right',
    options: [
      { value: 0, label: '草拟' },
      { value: 1, label: '在批' },
      { value: 2, label: '生效' }
    ]
  }
];

export const statisticalDetailsProperties = [{ label: '设计人', prop: 'stff_name', itemType: 'input', input: true, widthAuto: true, sortable: false }];
export const designDraftDetailsProperties = [
  { label: '产品设计单号', prop: 'desi_no', itemType: 'input', input: false, widthAuto: true, sortable: false },
  {
    prop: 'imge_url',
    itemType: 'input',
    widthAuto: true,
    label: '图片',
    input: false,
    sortable: false,
    subItem: {
      type: 'image',
      thumbnail: row => helper.picUrl(row.imge_url),
      image: row => helper.picUrl(row.imge_url)
    }
  },
  { label: '设计师', prop: 'desi_stff_name', itemType: 'input', input: true, widthAuto: true, sortable: false },
  {
    label: '设计日期',
    prop: 'desi_date',
    itemType: 'date',
    input: true,
    widthAuto: true,
    sortable: false,
    valueFormat: 'timestamp',
    formatter: val => getDateNoTime(val, true)
  },
  {
    label: '录入日期',
    prop: 'create_time',
    itemType: 'date',
    input: false,
    widthAuto: true,
    sortable: true,
    valueFormat: 'timestamp',
    formatter: val => getDateNoTime(val, true)
  },
  { label: '录入人', prop: 'stff_name', itemType: 'input', input: false, widthAuto: true, sortable: true }
];

export const statisticsOfCompletedTransactionsProperties = [
  {
    label: '产品图片',
    prop: 'imge_url',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '220',
    subItem: { type: 'image', image: row => row.imge_url, thumbnail: row => row.imge_url }
  },
  { label: '我司货号', prop: 'prod_no', itemType: 'input', input: false, widthAuto: true, sortable: false },
  { label: '中文品名', prop: 'prod_name', itemType: 'input', input: false, widthAuto: true, sortable: false },
  { label: '规格', prop: 'prod_spec', itemType: 'input', input: false, widthAuto: true, sortable: false },
  { label: '客户简称', prop: 'cust_abbr', itemType: 'input', input: true, widthAuto: true, sortable: false },
  { label: '设计人', prop: 'stff_name', itemType: 'input', input: true, widthAuto: true, sortable: false },
  { label: '难易程度', prop: 'desi_rank', itemType: 'input', input: false, widthAuto: true, sortable: false },
  {
    label: '商品库录入时间',
    prop: 'create_time',
    itemType: 'input',
    input: false,
    widthAuto: true,
    sortable: true,
    formatter: val => getDateNoTime(val, true)
  },
  { label: '销售合同金额', prop: 'prod_total_price', itemType: 'input', input: false, widthAuto: true, sortable: true }
];
